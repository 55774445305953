<template>
  <main class="article">
    <PageHeader :title="`Article - ${gameName}`" />
    <l-loading v-model="article">
      <div class="content">
        <h1>
          <span>{{article.title}}</span>
          <div class="title-other">
            <p>{{article.author}}</p><p>• {{article.length}} min read</p><p>• {{articleAge}}</p>
          </div>
        </h1>
        <section class="content" v-html="article.content"></section>
      </div>
    </l-loading>
  </main>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
  name: 'Article',
  data: () => ({
    article: null,
  }),
  computed: {
    gameName() {
      return this.$games.find((g) => g.slug === this.$store.state.game).name;
    },
    articleAge() {
      return moment(this.article.createdAt).fromNow();
    },
  },
  created() {
    axios.get(this.$api(`/articles/${this.$route.params.article}`))
      .then(response => {
        this.article = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
}
</script>

<style>

</style>